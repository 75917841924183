import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { BusinessAssetsFilingStep } from '../../models/api'
import { paths } from '../../utils/paths'
import Header from '../Layout/Header'
import NavigationDrawer from '../Layout/NavigationDrawer'
import Loader from '../Loader'

export default function ProtectedRoute(): JSX.Element {
  const { authInformation, sessionContext, logout } = useAuth()
  const classes = useStyles()
  const navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [openBanner, setOpenBanner] = useState(true)

  const hasCompletedSummaryAndSignature = useMemo(
    () => sessionContext?.completedSteps.includes(BusinessAssetsFilingStep.SummaryAndSignature),
    [sessionContext?.completedSteps],
  )

  useEffect(() => {
    const isExpired = (): boolean => {
      return !authInformation || Date.now() > authInformation.exp * 1000
    }

    if (authInformation && !isExpired()) {
      setLoggedIn(true)
    } else {
      logout?.()
      navigate(paths.loginWithPin)
    }
  }, [authInformation, logout, navigate])

  useEffect(() => {
    if (hasCompletedSummaryAndSignature) {
      setOpenBanner(true)
    }
  }, [hasCompletedSummaryAndSignature])

  const onDrawerOpen = (): void => setIsDrawerOpen(true)
  const onDrawerClose = (): void => setIsDrawerOpen(false)

  const handleCloseThankYouBanner = (): void => {
    setOpenBanner(false)
  }

  const ThankYouBanner = (
    <Collapse in={openBanner}>
      <Alert
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={handleCloseThankYouBanner}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {t('successfullyFiledTaxes')}
      </Alert>
    </Collapse>
  )

  if (loggedIn) {
    return (
      <React.Fragment>
        <Header onMenuClick={onDrawerOpen} />
        {hasCompletedSummaryAndSignature && ThankYouBanner}
        <NavigationDrawer open={isDrawerOpen} onDrawerClose={onDrawerClose} />
        <Container className={classes.root}>
          <Outlet />
        </Container>
      </React.Fragment>
    )
  }

  return <Loader loading={true} />
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxWidth: '1280px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
}))
