import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import HomeIcon from '@mui/icons-material/Home'
import Divider from '@mui/material/Divider'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { paths } from '../../utils/paths'
import ReopenFilingItem from '../ResetFilingItem'

interface Props extends Omit<DrawerProps, 'onClose' | 'classes'> {
  onDrawerClose?(): void
}

export default function NavigationDrawer(props: Props): JSX.Element {
  const { onDrawerClose, ...drawerProps } = props

  const { authInformation, logout } = useAuth()

  const { t } = useTranslation()

  const classes = useStyles()
  const isImpersonating = authInformation?.impersonatorId && authInformation?.impersonatorJurisdictionId

  return (
    <Drawer {...drawerProps} onClose={onDrawerClose} classes={classes}>
      <List>
        <ListItem>
          {authInformation && (
            <ListItemText
              primary={t('userIdLabel', { userId: authInformation.userId })}
              secondary={authInformation.email}
            />
          )}
          <IconButton onClick={onDrawerClose} aria-label={t('hideNavigationMenu')} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {!isImpersonating &&  <ListItem button component={Link} to={paths.home} onClick={onDrawerClose}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={t('businessAccounts')} />
        </ListItem>
        }

        <ReopenFilingItem onDrawerClose={onDrawerClose} />

        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('signOut')} />
        </ListItem>
      </List>
    </Drawer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: { width: theme.spacing(32) },
}))
