import RestartAltIcon from '@mui/icons-material/RestartAlt'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReopenFilingMutation } from '../../../generated/graphql'
import useAuth from '../../../hooks/useAuth'
import { BusinessAccount } from '../../../models/api'

interface Props {
  open: boolean
  onCancel: () => void
  onReset: () => void
}

export default function ReopenFilingDialog(props: Props): JSX.Element {
  const { open, onCancel, onReset } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const { businessAccount } = useAuth()
  const { mutateAsync: resetFilingMutation, isLoading } = useReopenFilingMutation()

  const [openError, setOpenError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleReset = async (): Promise<void> => {
    try {
      const businessContext = businessAccount as BusinessAccount
      await resetFilingMutation({
        accountId: parseInt(businessContext.accountId),
        jurisdiction: businessContext.jurisdiction.id,
      })

      onReset()
    } catch (error) {
      setOpenError(true)
      setErrorMessage(error instanceof Error ? error.message : t('oopsSomethingWentWrong'))
    }
  }

  const handleCloseError = (): void => {
    setOpenError(false)
    setErrorMessage('')
  }

  const dialogContent = isLoading ? (
    <Box className={classes.loadingBox}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Typography>{t('resetDeclarationDialogLine1')}</Typography>
      <Typography fontWeight="bold" mt={2}>
        {t('resetDeclarationDialogLine2')}
      </Typography>
    </>
  )

  return (
    <React.Fragment>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Box padding={1}>
          <DialogTitle fontWeight="bold">{t('resetDeclarationDialogTitle')}</DialogTitle>
          <DialogContent>{dialogContent}</DialogContent>
          <DialogActions>
            <Button onClick={onCancel} disabled={isLoading}>
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<RestartAltIcon />}
              onClick={handleReset}
              disabled={isLoading}
            >
              {t('resetDeclaration')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Snackbar open={openError} onClose={handleCloseError}>
        <Alert severity="error" variant="filled" onClose={handleCloseError}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))
