import jwtDecode, { JwtPayload } from 'jwt-decode'
import React from 'react'
import { SessionContext, BusinessAccount } from '../generated/graphql'
import { AuthInformation, FilingSessionContext, BusinessAccount as BusinessAccountContext } from '../models/api'
import { mapToStep } from '../utils/stepMap'
import useLocalStorage from './useLocalStorage'

interface AuthProviderProps {
  children: React.ReactNode
}

interface AppJwtPayload extends JwtPayload {
  userId: number
  email: string
  impersonatorId?: number
  impersonatorJurisdictionId?: number
  impersonatorJurisdictionName?: string
}

interface AuthContext {
  authInformation: AuthInformation
  businessAccount: BusinessAccountContext | null
  sessionContext: FilingSessionContext | null
  showDelinquentNoticeBanner?: boolean
  setShowDelinquentNoticeBanner?: React.Dispatch<boolean>
  login?: (token: string) => void
  logout?: () => void
  storeBusinessAccount?: (businessAccount: BusinessAccount) => void
  storeSessionContext?: (sessionContext: SessionContext) => void
  removeSessionContext?: () => void
}

export const AuthContext = React.createContext<AuthContext>({} as AuthContext)

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [authInformation, setAuthInformation] = useLocalStorage<AuthInformation | null>('authInformation', null)
  const [businessAccount, setBusinessAccount] = useLocalStorage<BusinessAccountContext | null>(
    'businessAccount',
    null,
  )
  const [sessionContext, setSessionContext] = useLocalStorage<FilingSessionContext | null>('sessionContext', null)
  const [showDelinquentNoticeBanner, setShowDelinquentNoticeBanner] = useLocalStorage<boolean>('showDelinquentNoticeBanner', true)

  const login = (token: string): void => {
    const { userId, email, impersonatorId, impersonatorJurisdictionId, impersonatorJurisdictionName, exp } =
      jwtDecode<AppJwtPayload>(token)
    const isAdmin = Boolean(impersonatorId && impersonatorJurisdictionId)
    setAuthInformation({
      token,
      userId,
      email,
      isAdmin,
      impersonatorId,
      impersonatorJurisdictionId,
      impersonatorJurisdictionName,
      exp: exp ?? 0,
    })
    setBusinessAccount(null)
    setSessionContext(null)
  }

  const logout = (): void => {
    setAuthInformation(null)
  }

  const storeSessionContext = async (sessionContext: SessionContext): Promise<void> => {
    const completedSteps = sessionContext.completedSteps.map(mapToStep)
    const newFilingSessionContext = {
      accountNumber: sessionContext.accountNumber,
      businessName: sessionContext.businessName,
      taxYear: sessionContext.taxYear,
      delinquentTaxYears: sessionContext.delinquentTaxYears,
      completedSteps: completedSteps,
    } as FilingSessionContext

    setSessionContext(newFilingSessionContext)
  }

  const removeSessionContext = (): void => {
    if(!(authInformation?.impersonatorJurisdictionId && authInformation.impersonatorId)) {
      setBusinessAccount(null)
    }
    setSessionContext(null)
  }

  const storeBusinessAccount = async (businessAccount: BusinessAccount): Promise<void> => {
    const { accountId, accountNumber, businessName, jurisdiction, address } = businessAccount
    const context = {
      accountNumber,
      businessName,
      accountId: accountId.toString(),
      jurisdiction: {
        id: parseInt(jurisdiction.id),
        name: jurisdiction.name,
      },
      situsAddress: `${address.addressLine1}, ${address.city}, ${address.zipCode}`,
    }
    setBusinessAccount(context)
  }

  const context = {
    login,
    logout,
    storeSessionContext,
    removeSessionContext,
    storeBusinessAccount,
    showDelinquentNoticeBanner,
    setShowDelinquentNoticeBanner,
    authInformation: authInformation as AuthInformation,
    businessAccount: businessAccount as BusinessAccountContext,
    sessionContext: sessionContext as FilingSessionContext,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export default function useAuth(): AuthContext {
  return React.useContext(AuthContext)
}
