import RestartAltIcon from '@mui/icons-material/RestartAlt'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { hasTheMainWorkFlowBeenCompleted } from '../../hooks/useForcedNavigation'
import { FilingSessionContext } from '../../models/api'
import { goToAccountInformation } from '../../utils/routeActions'
import ResetFilingDialog from './ResetFilingDialog'

interface Props {
  onDrawerClose?(): void
}

export default function ReopenFilingItem(props: Props): JSX.Element {
  const { onDrawerClose } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { sessionContext } = useAuth()

  const [currentSessionContext, setCurrentSessionContext] = useState<FilingSessionContext | null>(null)
  const [openResetFilingDialog, setOpenResetFilingDialog] = useState(false)

  useEffect(() => {
    setCurrentSessionContext(sessionContext)
  }, [sessionContext])

  const handleResetFiling = (): void => {
    setOpenResetFilingDialog(true)
  }

  const clearDialog = (): void => {
    setOpenResetFilingDialog(false)
    onDrawerClose?.()
  }

  const handleCancel = (): void => {
    clearDialog()
  }

  const handleReset = (): void => {
    clearDialog()
    goToAccountInformation(navigate)
  }

  if (currentSessionContext && hasTheMainWorkFlowBeenCompleted(currentSessionContext.completedSteps)) {
    return (
      <React.Fragment>
        <ListItem button onClick={handleResetFiling}>
          <ListItemIcon>
            <RestartAltIcon />
          </ListItemIcon>
          <ListItemText primary={t('resetDeclaration')} />
        </ListItem>
        <ResetFilingDialog open={openResetFilingDialog} onCancel={handleCancel} onReset={handleReset} />
      </React.Fragment>
    )
  }

  return <></>
}
