import MuiStep, { StepProps } from '@mui/material/Step'
import MuiStepButton from '@mui/material/StepButton'
import MuiStepLabel from '@mui/material/StepLabel'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends Omit<StepProps, 'onClick'> {
  label: string
  activeStep: number
  thisStep: number
  isCompleted?: boolean
  isDisabled?: boolean
  isOptional?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default function Step(props: Props): JSX.Element {

  const {
    label,
    activeStep,
    thisStep,
    isCompleted,
    isDisabled,
    isOptional,
    onClick,
    ...stepProps
  } = props

  const classes = useStyles()

  const { t } = useTranslation()

  const isActive = activeStep === thisStep

  return (
    <MuiStep {...stepProps} classes={{ root: classes.stepRoot }} completed={isCompleted}>
      <MuiStepButton
        classes={{ root: classes.stepButtonRoot }}
        disabled={isDisabled}
        // TODO: Maybe remove this property
        // Waiting on feedback from Material-UI team
        // https://github.com/mui-org/material-ui/issues/26304
        aria-current={isActive ? 'step' : undefined}
        onClick={isActive ? undefined : onClick}
        optional={isOptional ? (
          <Typography variant="caption">{t('optional')}</Typography>
        ) : undefined}
      >
        <MuiStepLabel classes={{ root: classes.labelRoot }}>
          {label}
        </MuiStepLabel>
      </MuiStepButton>
    </MuiStep>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  labelRoot: {
    '& .Mui-active': { fontWeight: theme.typography.fontWeightBold },
    '& .MuiStepLabel-label': {
      [theme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
      },
    },
  },
  stepRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepButtonRoot: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}))
