import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useForcedNavigation from '../../hooks/useForcedNavigation'
import { FetchError } from '../../models/api'
import { paths } from '../../utils/paths'
import FetchErrorSnack from '../FetchErrorSnack/FetchErrorSnack'
import Loader from '../Loader'
import LoadingFetchError from '../LoadingFetchError'

export default function ProtectedFilingRoute(): JSX.Element {
  const { businessAccount } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [hasSelectedBusiness, setHasSelectedBusiness] = useState(false)

  useEffect(() => {
    if (businessAccount) {
      setHasSelectedBusiness(true)
    } else {
      navigate(paths.home)
    }
  }, [businessAccount, navigate])

  const accountId = parseInt(businessAccount?.accountId ?? '')
  const jurisdiction = businessAccount?.jurisdiction.id ?? 0
  const { isSessionContextLoading, sessionContextHasErrors, sessionContextError, refetchSessionContext } =
    useForcedNavigation(accountId, jurisdiction)

  if (sessionContextHasErrors) {
    const error = sessionContextError as FetchError
    return (
      <React.Fragment>
        <Box mt={2}>
          <LoadingFetchError tryAgain={refetchSessionContext} />
          <FetchErrorSnack
            open={true}
            title={t('weCoundntFetchYourItemCorrectly', { item: t('sessionContext') })}
            content={error.message}
          />
        </Box>
      </React.Fragment>
    )
  }

  if (!hasSelectedBusiness || isSessionContextLoading) {
    return <Loader loading={true} />
  }

  return <Outlet />
}
