import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Snackbar from '@mui/material/Snackbar'
import React from 'react'

interface Props {
  title?: string
  content: string | JSX.Element
  onClose?: () => void
  open: boolean
}

export default function FetchErrorSnack(props: Props): JSX.Element {
  const { title, content, open, onClose } = props
  return (
    <Snackbar open={open} onClose={onClose}>
      <Alert severity="error" variant="filled" onClose={onClose}>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        {content}
      </Alert>
    </Snackbar>
  )
}
